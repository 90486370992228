<template>
  <b-container class="mt-3">
    <b-card body-class="bg-card" class="card-border mb-2 position-relative">
      <b-row>
        <b-col cols="md-6 sm-12">
          <b-card-header
            header="Dados Pessoais"
            header-class="c-header"
          >
          </b-card-header>
          <b-card-body>
            <strong>Nome:</strong> {{ personalData.name }}<br>
            <strong>Data de Nascimento:</strong> {{ personalData.birthDate | bdFormatter }}<br>
            <strong>Idade:</strong> {{ age }}<br>
            <strong>Gênero:</strong> {{ personalData.gender | genderFormatter }}<br>
            <div v-if="personalData.hasDeficiency">
              <strong>Possui Deficiência:</strong> {{ personalData.hasDeficiency | hdFormatter }}<br>
              <strong>Tipo de Deficiência</strong> {{ personalData.deficiencyType }}<br>
              <strong>CID</strong> {{ personalData.cid }}<br>
              <strong>Qual Deficiência?</strong> {{ personalData.cidDescription }}<br>
            </div>
            <strong>RG:</strong> {{ personalData.rg }}<br>
            <strong>CPF:</strong> {{ personalData.cpf }}<br>
            <strong>Período Escolar:</strong> {{ personalData.schoolTerm | schoolTermFormatter }}<br>
            <strong>Observações:</strong> {{ personalData.observations }}<br>
          </b-card-body>
        </b-col>

        <b-col cols="md-6 sm-12">
          <b-card-header
            header="Endereço"
            header-class="c-header"
          >
          </b-card-header>
          <b-card-body>
            <strong>CEP:</strong> {{ address.zipCode }}<br>
            <strong>Cidade:</strong> {{ address.city }}<br>
            <strong>Bairro:</strong> {{ address.district }}<br>
            <strong>Rua:</strong> {{ address.street }}<br>
            <strong>Número:</strong> {{ address.number }}<br>
            <strong>Complemento:</strong> {{ address.complement }}<br>
          </b-card-body>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="md-6 sm-12">
          <b-card-header
            header="Contatos"
            header-class="c-header"
          >
          </b-card-header>
          <b-card-group class="mt-2" v-for="(contact, index) in contacts" :key="index">
            <b-card body-class="bg-card" class="mb-2">
              <strong>Nome do contato:</strong> {{ contact.name }}<br>
              <strong>Tipo do contato:</strong> {{ contact.contactTypeName }}<br>
              <strong>Telefone 1:</strong> {{ contact.phone1 }}<br>
              <strong>Telefone 2:</strong> {{ contact.phone2 }}<br>
              <strong>Telefone 3:</strong> {{ contact.phone3 }}<br>
              <strong>E-mail:</strong> {{ contact.email }}<br>
            </b-card>
          </b-card-group>
        </b-col>

        <b-col cols="md-6 sm-12">
          <b-card-header
            header="Modalidade e Local"
            header-class="c-header"
          >
          </b-card-header>
          <b-card body-class="bg-card" class="mt-2">
            <div v-for="(modalityLocal, index) in modalitiesLocals" :key="index">
              <div>
                <strong>Local de Treinamento:</strong> {{ modalityLocal.localTrainingName }}
              </div>
              <div>
                <strong>Modalidade:</strong> {{ modalityLocal.modalityName }}
              </div>
              <div v-if="modalityLocal.presetClass">
                <strong>Turma/Horário:</strong> {{ modalityLocal.presetClass }}
              </div>
              <hr>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-overlay :show="isBusy" no-wrap></b-overlay>
    </b-card>

    <BackSaveButton
      :back="back"
      @onSave="handleSave"
      class="mt-3"
    />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import WaitingListRepository from '@/shared/http/repositories/socialProject/waitingList'
import WaitingList from '@/shared/models/waitingList'
import Address from '@/shared/models/address'
import BackSaveButton from '@/components/backSaveButton/BackSaveButton.vue'
import { mixin } from '@/shared/mixins'
import GENDERS from '@/shared/enums/genders'
import SCHOOL_TERMS from '@/shared/enums/schoolTerms'
import YES_NO from '@/shared/enums/yesNo'

export default {
  name: 'Confirmation',

  components: {
    [BackSaveButton.name]: BackSaveButton,
  },

  mixins: [mixin],

  data: () => ({
    back: 'Contact',
    isBusy: false,
    saveWithSuccess: false
  }),

  computed: {
    ...mapGetters({
      personalData: 'personalDataModule/personalData',
      age: 'personalDataModule/age',
      address: 'addressModule/address',
      contacts: 'contactModule/contacts',
      modalitiesLocals: 'modalityLocalTrainingModule/modalitiesLocals',
      isNeedValidatePresetClass: 'modalityLocalTrainingModule/isNeedValidatePresetClass',
      companyId: 'personalDataModule/companyId'
    })
  },

  filters: {
    bdFormatter(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
    },

    genderFormatter(value) {
      return Object.values(GENDERS).find(gender => gender.value === value)?.text
    },

    schoolTermFormatter(value) {
      return Object.values(SCHOOL_TERMS).find(schoolTerm => schoolTerm.value === value)?.text
    },

    hdFormatter(value) {
      return Object.values(YES_NO).find(x => x.value === value)?.text
    }
  },

  methods: {
    handleSave() {
      this.isBusy = true
      this.saveWithSuccess = false

      const address = new Address({
        zipCode: this.address.zipCode,
        city: this.address.city,
        cityId: this.address.cityId,
        district: this.address.district,
        street: this.address.street,
        number: this.address.number,
        complement: this.address.complement,
      })

      const waitingList = new WaitingList({
        name: this.personalData.name,
        gender: this.personalData.gender,
        rg: this.personalData.rg,
        cpf: this.personalData.cpf,
        birthDate: this.personalData.birthDate,
        schoolTerm: this.personalData.schoolTerm,
        hasDeficiency: this.personalData.hasDeficiency,
        deficiencyTypeId: this.personalData.deficiencyTypeId,
        observations: this.personalData.observations,
        cidId: this.personalData.cidId,
        address: address,
        contacts: this.contacts,
        localTrainingModalities: this.modalitiesLocals,
        companyId: this.companyId,
        isNeedValidatePresetClass: this.isNeedValidatePresetClass
      })

      WaitingListRepository.Create(waitingList)
        .then(res => {
          if (res.data.code !== 0) return Promise.reject(res.data.resultException)

          this.isBusy = false
          this.$bvToast.toast('Cadastro salvo com sucesso.', {
            title: 'Sucesso',
            variant: 'success',
            autoHideDelay: 2000
          })

          Promise.resolve()
          this.saveWithSuccess = true
          return this.$router.replace({ name: 'SendedForm' })
        })
        .catch(e => {
          this.isBusy = false
          this.saveWithSuccess = false

          if (e.Message === 'Student already saved') {
            this.$bvToast.toast('CPF já cadastrado', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 2000
            })
          } else if (e.Message === 'No Vacancy Available') {
            this.$bvToast.toast('Turma excedeu o limite. Selecione outra', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 3500
            })
          } else {
            this.$bvToast.toast('Erro ao salvar na lista de espera.', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 2000
            })
          }
        })
    }
  },

  beforeRouteEnter(to, from, next) {
    if(from.name === 'Contact') {
      return next()
    }

    return next(false)
  },

  beforeRouteLeave(to, from, next) {
    if(to.name === 'SendedForm' && this.saveWithSuccess === false) return next(false)

    if(to.name === 'Dashboard' || to.name === 'Infos' || to.name === 'NotFound' || to.name === 'SendedForm') {
      this.clearState()
      return next()
    }

    return next()
  }
}
</script>

<style>
  .c-header {
    font-weight: bold;
    text-align: center;
    border-bottom: none;
    font-family: 'Raleway', sans-serif;
  }

  li::marker {
    font-weight: bold;
  }
</style>
