export default class WaitingList {
  constructor({
    name,
    gender,
    rg,
    cpf,
    birthDate,
    schoolTerm,
    hasDeficiency,
    deficiencyTypeId,
    cidId,
    observations,
    address,
    contacts,
    localTrainingModalities,
    companyId,
    isNeedValidatePresetClass
  } = {}) {
    this.name = name
    this.gender = gender
    this.rg = rg
    this.cpf = cpf
    this.birthDate = birthDate
    this.schoolTerm = schoolTerm
    this.hasDeficiency = hasDeficiency
    this.deficiencyTypeId = deficiencyTypeId
    this.cidId = cidId
    this.observations = observations
    this.address = address
    this.contacts = contacts
    this.localTrainingModalities = localTrainingModalities
    this.companyId = companyId
    this.isNeedValidatePresetClass = isNeedValidatePresetClass
  }
}
